<template>
  <e-btn-circle-status v-if="s === helper.STATUS_SOLICITADO" status="blue" class="sm m-r-xs" />
  <u-icon v-else-if="s === helper.STATUS_COTACAO" name="dollar-sign" type="fa" icon-style="solid" class="fa-fw m-r-xs" />
  <u-icon v-else-if="s === helper.STATUS_REMOVIDO" name="truck" type="fa" icon-style="solid" class="fa-fw m-r-xs" />
  <u-icon name="exchange" type="fa" icon-style="light" v-else-if="s === helper.STATUS_EM_TRANSITO" class="fa-fw m-r-xs" />
  <u-icon v-else-if="s === helper.STATUS_AGUARDANDO_VISTORIA" name="tasks" type="fa" icon-style="solid" class="fa-fw m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_ENTREGUE" status="positive" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_NAO_ENTREGUE" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_CANCELADO" status="negative" class="sm m-r-xs" />
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../domain/remocao/helpers/Status'
export default {
  name: 'StatusRemocaoIcon',
  props: ['status'],
  components: {EBtnCircleStatus},
  computed: {
    helper () {
      return Status
    },
    s () {
      return parseFloat(this.status)
    }
  }
}
</script>

<style scoped>

</style>
