<template>
  <div class="flex items-center">
    <status-remocao-icon class="fa-fw" :status="status" />
    <span class="text-black">{{getStatus(status).title}}</span>
  </div>
</template>

<script>
import {Status, getStatus} from '@/domain/remocao/helpers/Status'
import StatusRemocaoIcon from './StatusRemocaoIcon';

export default {
  name: 'StatusRemocaoSelector',
  components: {StatusRemocaoIcon},
  props: ['status'],
  computed: {
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    }
  }
}
</script>

<style scoped>

</style>
