<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import {list} from '@/domain/remocao/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import StatusRemocao from './include/StatusRemocao'
import ContextMenuRemocaoItem from './context/remocao-list-context'
import MenuOptions from '../../layout/context-menu/context-window-options'
import RemocaoListMenu from './context/remocao-list-menu'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import RemocaoMixin from '../mixin'
import StatusSelect from './include/StatusSelect'
import ReboquistaSelect from './include/ReboquistaSelect'
import UfSelect from './include/UfSelect'
import CidadeSelect from './include/CidadeSelect'
import DateInput from '../../../reuse/input/Date'
import remocaoWindow from '../windows/remocao'
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import TipoBemSelect from "@/components/bem/components/include/TipoSelect";
import MarcaSelect from "@/components/bem/components/include/MarcaSelect";
import ModeloSelect from "@/components/bem/components/include/ModeloSelect";
import {datePtToEn} from "@/utils/date";

let filters = {
  comitente: null,
  codigoBem: null,
  tipoBem: null,
  marca: null,
  modelo: null,
  ano: null,
  placa: null,
  chassi: null,
  codigo: null,
  situacao: [],
  reboquista: null,
  ufOrigem: null,
  cidadeOrigem: null,
  destino: null,
  dataSolicitacao1: null,
  dataSolicitacao2: null
}

export default {
  name: 'Remocoes',
  mixins: [RemocaoMixin],
  components: {
    ModeloSelect,
    MarcaSelect,
    TipoBemSelect,
    ComitenteSelect,
    DateInput,
    CidadeSelect,
    UfSelect,
    ReboquistaSelect,
    StatusSelect,
    SearchFilter,
    RemocaoListMenu,
    MenuOptions,
    ContextMenuRemocaoItem,
    StatusRemocao,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSField
  },
  data () {
    return {
      filtersData: {
        loadedAll: false,
        loadedAllError: false,
        comitentes: {
          loading: true,
          serverData: []
        },
        tipoBem: {
          loading: true,
          serverData: []
        },
        marca: {
          loading: true,
          serverData: []
        },
        modelo: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: [
          {name: 'codigo', label: 'Código', field: 'codigo', align: 'left', sortable: true},
          {name: 'registro', label: 'Registro', field: 'registro', align: 'left', sortable: true},
          {name: 'conclusao', label: 'Conclusão', field: 'conclusao', align: 'left', sortable: true},
          {name: 'reboquista', label: 'Reboquista', field: 'reboquista', align: 'left', sortable: true},
          {
            name: 'comitente',
            required: true,
            label: 'Comitente',
            align: 'left',
            field: 'comitente',
            sortable: true
          },
          {
            name: 'bem',
            required: true,
            label: 'Bem',
            align: 'left',
            field: 'bem',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    },
    areDatasSolicitacaoEmpty () {
      return [
        this.table.filters.dataSolicitacao1,
        this.table.filters.dataSolicitacao2
      ].every(d => !d?.trim())
    }
  },
  mounted () {
    // document.querySelector('.u-erp-layout-container').classList.add('bg-white')
    this.load()
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request ({pagination, filter}) {
      const tableFilters = JSON.parse(JSON.stringify(this.table.filters))
      this.processDatesFilters(tableFilters)

      this.table.loading = true

      const extraFilters = []
      const configFilters = Object.keys(tableFilters)
      for (const config of configFilters) {
        tableFilters[config] && extraFilters.push(`${config}=${tableFilters[config]}`)
      }

      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&${extraFilters.join('&')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.loading = false
          })
    },
    processDatesFilters (tableFilters) {
      let dataSolicitacao1, dataSolicitacao2
      if (!this.areDatasSolicitacaoEmpty && (!tableFilters.dataSolicitacao1 || !tableFilters.dataSolicitacao2)) {
        alert('Informe um intervalo de datas para processar o filtro')
        return
      }
      if (tableFilters.dataSolicitacao1) {
        if (tableFilters.dataSolicitacao1.length < 10 || tableFilters.dataSolicitacao2.length < 10) {
          alert('Digite a data inicial e data final corretamente')
          return
        }
        dataSolicitacao1 = datePtToEn(tableFilters.dataSolicitacao1)
        dataSolicitacao2 = datePtToEn(tableFilters.dataSolicitacao2)

        if (!date.isValid(dataSolicitacao1) || !date.isValid(dataSolicitacao2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        tableFilters.dataSolicitacao1 = dataSolicitacao1
        tableFilters.dataSolicitacao2 = dataSolicitacao2
      }
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.remocaoWindow()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    remocaoWindow: remocaoWindow,
    updateList (v) {
      this.load()
    }
  },
  meta: {
    title: 'Remoções',
    name: 'Remoções'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact"
          menu-active="remocoes">
    <div>
      <search-filter>
        <form id="remocaoFilter">
          <div class="row erp-row def-m-r">

            <div class="col">
              <comitente-select @loaded="$emit('loadedComitente')" multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="table.filters.comitente" />
            </div>

            <div class="col" style="max-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Código do Bem"
              >
                <erp-input shortkey="F3" v-model="table.filters.codigoBem" />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 130px">
              <tipo-bem-select
                  ref="tipoPai"
                  placeholder="Qualquer"
                  label="Tipo de bem:"
                  field-view="tl"
                  list-all=""
                  v-model="table.filters.tipoBem" />
            </div>

            <div class="col" style="max-width: 130px">
              <marca-select placeholder="Selecione" label-width="110px"
                            label="Montadora/Marca:"
                            disable-create
                            v-model="table.filters.marca" />
            </div>

            <div class="col" style="max-width: 130px">
              <modelo-select
                  :disable="!table.filters.marca"
                  :marca="table.filters.marca"
                  placeholder="Selecione"
                  label="Modelo:"
                  disable-create
                  v-model="table.filters.modelo" />
            </div>

            <div class="col" style="max-width: 75px">
              <erp-s-field
                  view="tl"
                  label="Ano Modelo"
              >
                <erp-input v-model="table.filters.ano" />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 75px">
              <erp-s-field
                  view="tl"
                  label="Placa"
              >
                <erp-input shortkey="F4" v-model="table.filters.placa" />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 160px">
              <erp-s-field
                  view="tl"
                  label="Chassi"
              >
                <erp-input v-model="table.filters.chassi" />
              </erp-s-field>
            </div>

          </div>

          <div class="row erp-row m-t-sm def-m-r">
            <div class="col" style="max-width: 126px">
              <erp-s-field
                  view="tl"
                  label="Código da Remoção"
              >
                <erp-input shortkey="F2" v-model="table.filters.codigo" />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 130px">
              <status-select multiple v-model="table.filters.situacao" />
            </div>

            <div class="col">
              <reboquista-select v-model="table.filters.reboquista" />
            </div>

            <div class="col" style="max-width: 93px">
              <uf-select label="UF Origem" v-model="table.filters.ufOrigem" />
            </div>

            <div class="col" style="max-width: 176px">
              <cidade-select :uf="table.filters.ufOrigem" label="Cidade Origem" v-model="table.filters.cidadeOrigem" />
            </div>

            <div class="col">
              <cidade-select label="Pátio Destino" v-model="table.filters.destino" />
            </div>
          </div>

          <div class="row erp-row m-t-sm def-m-r">
            <div class="col" style="max-width: 126px">
              <date-input label="Data de Solicitação (De)" v-model="table.filters.dataSolicitacao1" />
            </div>

            <div class="col" style="max-width: 126px">
              <date-input label="Data de Solicitação (Até)" v-model="table.filters.dataSolicitacao2" />
            </div>

            <div class="col flex justify-end m-t-sm def-list-buttons">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar" @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']" @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column">
                <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']" @shortkey.native="novo" />
                <span class="shortcut">Shift + N</span>
              </div>
            </div>
          </div>
        </form>
      </search-filter>
      <div class="erp-list">
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="remocaoWindow(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td key="codigo" :props="props">
              {{props.row.id}}
            </u-td>
            <u-td key="registro" :props="props">
              {{props.row.createdAt.date|formatDate('dd/MM/yyyy')}}
            </u-td>
            <u-td key="conclusao" :props="props">
              <span v-if="props.row.dataConclusao">{{props.row.dataConclusao.date|formatDate('dd/MM/yyyy')}}</span>
              <span v-else>-</span>
            </u-td>
            <u-td :title="props.row.reboquista && props.row.reboquista.pessoa.name" class="td-limit l-130" key="reboquista"
                  :props="props">
              {{props.row.reboquista && props.row.reboquista.pessoa.name}}
            </u-td>
            <u-td :title="props.row.bem ? props.row.bem.comitente.pessoa.name : ''" class="td-limit l-130" key="comitente"
                  :props="props">
              {{props.row.bem && props.row.bem.comitente.pessoa.name}}
            </u-td>
            <u-td class="td-limit" key="bem" :props="props">
              {{props.row.bem && props.row.bem.descricao}}
            </u-td>
            <u-td class="text-uppercase" key="status" :props="props">
              <status-remocao :remocao="props.row" />
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <remocao-list-menu @abrir="remocaoWindow(props.row.id)" :remocao="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-remocao-item @abrir="remocaoWindow(props.row.id)" :remocao="props.row" />
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>
