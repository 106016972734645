<template>
  <erp-s-field
      view="tl"
      label="Reboquista"
  >
    <erp-select
        @input="__set"
        placeholder="Qualquer"
        :options="reboquistas"
        v-model="model"
        :multiple="multiple"
    />
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'

export default {
  name: 'ReboquistaSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    reboquistas () {
      let list = [
        {id: 1, label: 'Reboquista 01', value: 1}
      ]
      !this.multiple && list.unshift({label: 'Qualquer', value: null})

      return list
    }
  },
  components: {},
  methods: {}
}
</script>
